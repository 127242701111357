import tailwindConfig from 'tailwind-config';

const extend = tailwindConfig.theme.extend;

Object.entries(extend.colors.primary).forEach(([key, value]) => {
  // if value is css variable, get fallback hex color
  if (value.includes(', ')) {
    const fallbackColor = value.split(', ')[1].replace(')', '');
    extend.colors.primary[key] = fallbackColor;
  }
});

export default extend;
